@font-face {
    font-family: 'Nanum Gothic';
    font-style: normal;
    font-weight: 300;
    src: url("../src/Fonts/NanumGothicLight.woff2") format('woff2'),
    url("../src/Fonts/NanumGothicLight.woff") format('woff'),
    url("../src/Fonts/NanumGothicLight.otf") format('otf')
  }
  
  @font-face {
    font-family: 'Nanum Gothic';
    font-style: normal;
    font-weight: normal;
    src: url("../src/Fonts/NanumGothic.woff2") format('woff2'),
    url("../src/Fonts/NanumGothic.woff") format('woff'),
    url("../src/Fonts/NanumGothic.otf") format('otf')
  }
  
  @font-face {
    font-family: 'Nanum Gothic';
    font-style: normal;
    font-weight: bold;
    src: url("../src/Fonts/NanumGothicBold.woff2") format('woff2'),
    url("../src/Fonts/NanumGothicBold.woff") format('woff'),
    url("../src/Fonts/NanumGothicBold.otf") format('truetype')
  }
  
  /* (+) font-weight
  300 - Light
  400 - Normal
  700 - Bold
  800 - Extra Bold (Ultra Bold) */



  .font-test{
      font-family: "Nanum Gothic";
  }